<template>
  <div>
    <div
      class="align-center text-center mt-6 mx-6"
      v-if="userRole === 'master'"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.devicesList") }}</v-toolbar-title>
        </v-toolbar>
        <div v-if="$i18n.locale == 'en'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headers"
            :search="search"
            :items="devices"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <br />
                        <h3>Are you sure you want to delete the device?</h3>
                        <h3>This action cannot be reverted.</h3>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancel
                    </v-btn>
                    <v-btn outlined color="#EF5350" text @click="save">
                      Delete
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>

        <div v-if="$i18n.locale == 'pt'" class="pl-4 pr-4 pt-2 pb-2">
          <v-data-table
            :headers="headersPt"
            :search="search"
            :items="devices"
            :items-per-page="10"
            fixed-header
            class="elevation-1"
          >
            <!--TABLE TEMPLATE - CRUD METHODS-->
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <v-icon x-large color="#EF5350">warning</v-icon>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <p>
                          Tem a certeza que pretende eliminar o dispositivo?
                        </p>
                        <p>Não poderá voltar atrás nesta acção.</p>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn outlined color="blue darken-1" text @click="close">
                      Cancelar
                    </v-btn>
                    <v-btn outlined color="#EF5350" text @click="save">
                      Eliminar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon medium @click="deleteItem(item)">delete</v-icon>
            </template>
          </v-data-table>

          <v-row>
            <v-col cols="8"> </v-col>

            <v-col cols="4" class="mt-2 pt-0" style="">
              <v-card-title>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  :label="$t('tables.search')"
                  single-line
                  hide-details
                  outlined
                ></v-text-field>
              </v-card-title>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </div>

    <div
      v-if="userRole === 'master'"
      class="align-center text-center mt-1 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{
            $t("tables.updateDevicePackage")
          }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex mt-4 mx-0">
          <v-flex class="md3 mt-3 ml-6">
            <v-text-field
              v-model="serialNumberUpdate"
              label="Serial Number"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md3 ml-3">
            <v-container fluid>
              <v-combobox
                class=""
                v-model="devicePackageUpdate"
                :items="packageItems"
                :search-input.sync="packageChosen"
                hide-selected
                hint="Maximum of 1 value"
                label="Choose Package"
                multiple
                persistent-hint
                small-chips
                outlined
              >
              </v-combobox>
            </v-container>
          </v-flex>
          <br />
          <v-btn class="ml-6 mt-6" elevation="2" @click="updateDevice()">
            {{ $t("button.update") }}
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="updateError" />
        <div class="green--text" v-html="updateSuccess" />
      </v-card>
    </div>

    <div
      v-if="userRole === 'master'"
      class="align-center text-center mt-1 mx-6"
    >
      <v-card elevation="6" style="margin: auto" max-width="2000">
        <v-toolbar flat dense class="blue darken-3" dark>
          <v-toolbar-title>{{ $t("tables.addDevice") }}</v-toolbar-title>
        </v-toolbar>
        <div class="d-flex ml-6 mt-4 mx-0">
          <v-flex class="md3 mt-3">
            <v-text-field
              v-model="mac"
              label="MAC Address"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md3 mt-3 ml-6">
            <v-text-field
              v-model="serialNumber"
              label="Serial Number"
              autocomplete="off"
              filled
              outlined
              background-color="white"
            ></v-text-field>
          </v-flex>
          <v-flex class="md3 ml-3">
            <v-container fluid>
              <v-combobox
                class=""
                v-model="devicePackage"
                :items="packageItems"
                :search-input.sync="packageChosen1"
                hide-selected
                hint="Maximum of 1 value"
                label="Choose Package"
                multiple
                persistent-hint
                small-chips
                outlined
              >
              </v-combobox>
            </v-container>
          </v-flex>

          <!-- TODO: Implement input validation -->
          <!-- <div class="red--text" v-html="error" /> -->
          <br />
          <v-btn
            class="ml-6 mt-6"
            min-width="80px"
            elevation="2"
            @click="addDevice()"
          >
            {{ $t("button.add") }}
          </v-btn>
        </div>
        <br />
        <div class="red--text" v-html="error" />
        <div class="green--text" v-html="success" />
      </v-card>
    </div>
  </div>
</template>

<script>
import DeviceService from "@/services/DeviceService";
import GroupService from "@/services/GroupService";
import AppService from "@/services/AppService";
import HelperMethods from "@/utilities/HelperMethods";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userInfo: {},
      devices: [],
      headers: [
        { text: "MAC Address", value: "mac" },
        { text: "Serial Number", value: "serialNumber" },
        { text: "Package", value: "packageName" },
        { text: "Group", value: "groupName" },
        { text: "Date", value: "createdAt" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      headersPt: [
        { text: "Endereço MAC", value: "mac" },
        { text: "Número Série", value: "serialNumber" },
        { text: "Pacote", value: "packageName" },
        { text: "Grupo", value: "groupName" },
        { text: "Data", value: "createdAt" },
        { text: "Acções", value: "actions", sortable: false },
      ],
      packageItems: [
        "Individual",
        "Multiple",
        "MINIUS",
        "LIMIA",
        "NERBIS",
        "TURIA",
        "None",
      ],
      groups: [],
      search: "",
      mac: "",
      serialNumber: "",
      serialNumberUpdate: "",
      packageChosen: "",
      packageChosen1: "",
      refPackage: null,
      devicePackage: null,
      devicePackageUpdate: null,
      updateError: null,
      error: null,
      updateSuccess: null,
      success: null,

      //v-data-table template variables
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      deviceToDeleteId: null,
    };
  },
  computed: {
    ...mapState(["userRole"]),
    formTitle() {
      if (this.$i18n.locale == "pt") {
        return this.editedIndex === -1
          ? "Nova Entrada"
          : "Eliminar Dispositivo";
      } else return this.editedIndex === -1 ? "New Item" : "Delete Device";
    },
  },
  methods: {
    async retrieveEntries() {
      let self = this;
      try {
        const response = await DeviceService.getAllDevices();
        this.devices = response.data;

        let response1 = await GroupService.getAllGroups();
        this.groupObj = response1.data;
      } catch (err) {
        console.log(err);
      }

      let groups = this.groupObj;

      function namePackage(packageId) {
        return self.packageItems[packageId - 1];
      }

      function nameGroups(groupId) {
        for (let i = 0; i < groups.length; i++) {
          if (groupId === groups[i].id) {
            return groups[i].name;
          }
        }
      }

      this.devices.forEach(function (value) {
        value.createdAt = HelperMethods.formatTimestamp(value["createdAt"]);
        value.groupName = nameGroups(value["refGroup"]);
        value.packageName = namePackage(value["refPackage"]);
      });
    },

    async refreshList() {
      await this.retrieveEntries();
    },

    async updateDevice() {
      let refPackage = this.getPackageRef(this.devicePackageUpdate);
      try {
        const response = await DeviceService.updateDevice({
          mac: this.mac,
          serialNumber: this.serialNumberUpdate,
          refPackage: refPackage,
        });
        this.updateError = null;
        this.updateSuccess = "Package successfully updated.";
        this.refreshList();
        console.log("Response: ", response);

        const response1 = await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `update device SN: ${this.serialNumber} to package:  ${this.devicePackageUpdate}`,
        });
        console.log("Response1: ", response1);
      } catch (error) {
        this.updateError = error.response.data.error;
      }
    },

    getPackageRef(packageName) {
      let refPackage = null;
      switch (packageName[0]) {
        case "Individual":
          refPackage = 1;
          return refPackage;
        case "Multiple":
          refPackage = 2;
          return refPackage;
        case "MINIUS":
          refPackage = 3;
          return refPackage;
        case "LIMIA":
          refPackage = 4;
          return refPackage;
        case "NERBIS":
          refPackage = 5;
          return refPackage;
        case "TURIA":
          refPackage = 6;
          return refPackage;
        case "None":
          refPackage = null;
          return refPackage;
        case null:
          refPackage = null;
          return refPackage;
        default:
          refPackage = null;
          return refPackage;
      }
    },

    async addDevice() {
      // let self = this;
      let refPackage;

      if (this.devicePackage != null) {
        refPackage = this.getPackageRef(this.devicePackage);
      } else refPackage = null;

      try {
        const response = await DeviceService.addDevice({
          mac: this.mac,
          serialNumber: this.serialNumber,
          refPackage: refPackage,
        });
        this.error = null;
        this.success = "Device successfully added.";
        this.refreshList();
        console.log("Response: ", response);

        const response1 = await AppService.addUserAction({
          userId: this.userInfo.id,
          userRole: this.userInfo.refRole,
          message: `add device with serial:  ${this.serialNumber}`,
        });
        console.log("Response1: ", response1);
      } catch (error) {
        this.error = error.response.data.error;
      }
    },

    async deleteEntry(id) {
      DeviceService.delete(id)
        .then(() => {
          this.refreshList();
          const response1 = AppService.addUserAction({
            userId: this.userInfo.id,
            userRole: this.userInfo.refRole,
            message: `delete device with serial:  ${this.serialNumber}`,
          });
          console.log("Response: ", response1);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    deleteItem(item) {
      this.editedIndex = this.devices.indexOf(item);
      this.deviceToDeleteId = item.id;
      this.dialog = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },

    save() {
      this.deleteEntry(this.deviceToDeleteId);
      this.close();
    },
  },
  async mounted() {
    this.userInfo = this.$store.getters.userInfo;
    await this.retrieveEntries();
  },
  watch: {
    //Combobox v-model devicePackage is being watched to prevent the selection of more than 1 value
    devicePackage(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.devicePackage.pop());
      }
    },
    devicePackageUpdate(val) {
      if (val.length > 1) {
        this.$nextTick(() => this.devicePackageUpdate.pop());
      }
    },
  },
};
</script>

<style scoped></style>
